'use client';

import { signIn } from 'actions/auth';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function Login() {
  const router = useRouter();

  useEffect(() => {
    const func = async () => {
      const redirectUrl = await signIn();
      router.push(redirectUrl)
    };

    func();
  }, [router]);

  return <div>Signing in...</div>;
}